import React from "react"

const Card = ({ children, className, ...otherProps }) => (
  <div
    className={`bg-white rounded-3xl filter drop-shadow-lg ${className}`}
    {...otherProps}
  >
    {children}
  </div>
)

export default Card
